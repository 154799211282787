// ------------------------------------------------------------
// ICONS
// ------------------------------------------------------------

/**
  // DO NOT EDIT DIRECTLY!
  //Generated by gulpfile.js/tasks/iconFont.js
  //from ./gulpfile.js/lib/template.scss
*/

@font-face {
  font-family: icons;
  src: url("../fonts/icons/icons.eot");
  src: url("../fonts/icons/icons.eot?#iefix") format('embedded-opentype'), url("../fonts/icons/icons.woff") format('woff'), url("../fonts/icons/icons.ttf") format('truetype'), url("../fonts/icons/icons.svg#icons") format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icon($content:'') {
  display: inline-block;
  font-family: 'icons';
  font-size: inherit;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  text-rendering: auto;
  transform: translate(0, 0);

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';

  box-sizing: border-box;

  @content;

  &:before {
    content: $content;
  }
}

.icon {
  @include icon();
}

.icon-fw {
  width: 1.28571429em;
  text-align: center;
}

.icon-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.icon-x2 { font-size:2em;}
.icon-x3 { font-size:3em;}
.icon-x4 { font-size:4em;}
.icon-x5 { font-size:5em;}

// Save variable
$icon-btn-apple-music: "\EA01";
// Expose as class
.icon.icon-btn-apple-music:before {
  content: $icon-btn-apple-music;
}
// Save mixin
@mixin icon--btn-apple-music {
  @include icon($icon-btn-apple-music) {
    @content;
  }
}

// Save variable
$icon-btn-close: "\EA02";
// Expose as class
.icon.icon-btn-close:before {
  content: $icon-btn-close;
}
// Save mixin
@mixin icon--btn-close {
  @include icon($icon-btn-close) {
    @content;
  }
}

// Save variable
$icon-btn-deezer: "\EA03";
// Expose as class
.icon.icon-btn-deezer:before {
  content: $icon-btn-deezer;
}
// Save mixin
@mixin icon--btn-deezer {
  @include icon($icon-btn-deezer) {
    @content;
  }
}

// Save variable
$icon-btn-fb: "\EA04";
// Expose as class
.icon.icon-btn-fb:before {
  content: $icon-btn-fb;
}
// Save mixin
@mixin icon--btn-fb {
  @include icon($icon-btn-fb) {
    @content;
  }
}

// Save variable
$icon-btn-googleplus: "\EA05";
// Expose as class
.icon.icon-btn-googleplus:before {
  content: $icon-btn-googleplus;
}
// Save mixin
@mixin icon--btn-googleplus {
  @include icon($icon-btn-googleplus) {
    @content;
  }
}

// Save variable
$icon-btn-ig: "\EA06";
// Expose as class
.icon.icon-btn-ig:before {
  content: $icon-btn-ig;
}
// Save mixin
@mixin icon--btn-ig {
  @include icon($icon-btn-ig) {
    @content;
  }
}

// Save variable
$icon-btn-menu: "\EA07";
// Expose as class
.icon.icon-btn-menu:before {
  content: $icon-btn-menu;
}
// Save mixin
@mixin icon--btn-menu {
  @include icon($icon-btn-menu) {
    @content;
  }
}

// Save variable
$icon-btn-napster: "\EA08";
// Expose as class
.icon.icon-btn-napster:before {
  content: $icon-btn-napster;
}
// Save mixin
@mixin icon--btn-napster {
  @include icon($icon-btn-napster) {
    @content;
  }
}

// Save variable
$icon-btn-slider-next: "\EA09";
// Expose as class
.icon.icon-btn-slider-next:before {
  content: $icon-btn-slider-next;
}
// Save mixin
@mixin icon--btn-slider-next {
  @include icon($icon-btn-slider-next) {
    @content;
  }
}

// Save variable
$icon-btn-slider-prev: "\EA0A";
// Expose as class
.icon.icon-btn-slider-prev:before {
  content: $icon-btn-slider-prev;
}
// Save mixin
@mixin icon--btn-slider-prev {
  @include icon($icon-btn-slider-prev) {
    @content;
  }
}

// Save variable
$icon-btn-snapchat: "\EA0B";
// Expose as class
.icon.icon-btn-snapchat:before {
  content: $icon-btn-snapchat;
}
// Save mixin
@mixin icon--btn-snapchat {
  @include icon($icon-btn-snapchat) {
    @content;
  }
}

// Save variable
$icon-btn-soundcloud: "\EA0C";
// Expose as class
.icon.icon-btn-soundcloud:before {
  content: $icon-btn-soundcloud;
}
// Save mixin
@mixin icon--btn-soundcloud {
  @include icon($icon-btn-soundcloud) {
    @content;
  }
}

// Save variable
$icon-btn-spotify: "\EA0D";
// Expose as class
.icon.icon-btn-spotify:before {
  content: $icon-btn-spotify;
}
// Save mixin
@mixin icon--btn-spotify {
  @include icon($icon-btn-spotify) {
    @content;
  }
}

// Save variable
$icon-btn-tumblr: "\EA0E";
// Expose as class
.icon.icon-btn-tumblr:before {
  content: $icon-btn-tumblr;
}
// Save mixin
@mixin icon--btn-tumblr {
  @include icon($icon-btn-tumblr) {
    @content;
  }
}

// Save variable
$icon-btn-tw: "\EA0F";
// Expose as class
.icon.icon-btn-tw:before {
  content: $icon-btn-tw;
}
// Save mixin
@mixin icon--btn-tw {
  @include icon($icon-btn-tw) {
    @content;
  }
}

// Save variable
$icon-btn-yt: "\EA10";
// Expose as class
.icon.icon-btn-yt:before {
  content: $icon-btn-yt;
}
// Save mixin
@mixin icon--btn-yt {
  @include icon($icon-btn-yt) {
    @content;
  }
}

// Save variable
$icon-messenger: "\EA11";
// Expose as class
.icon.icon-messenger:before {
  content: $icon-messenger;
}
// Save mixin
@mixin icon--messenger {
  @include icon($icon-messenger) {
    @content;
  }
}

// Save variable
$icon-picto-add-color: "\EA12";
// Expose as class
.icon.icon-picto-add-color:before {
  content: $icon-picto-add-color;
}
// Save mixin
@mixin icon--picto-add-color {
  @include icon($icon-picto-add-color) {
    @content;
  }
}

// Save variable
$icon-picto-check: "\EA13";
// Expose as class
.icon.icon-picto-check:before {
  content: $icon-picto-check;
}
// Save mixin
@mixin icon--picto-check {
  @include icon($icon-picto-check) {
    @content;
  }
}

// Save variable
$icon-picto-loupe: "\EA14";
// Expose as class
.icon.icon-picto-loupe:before {
  content: $icon-picto-loupe;
}
// Save mixin
@mixin icon--picto-loupe {
  @include icon($icon-picto-loupe) {
    @content;
  }
}

// Save variable
$icon-picto-share: "\EA15";
// Expose as class
.icon.icon-picto-share:before {
  content: $icon-picto-share;
}
// Save mixin
@mixin icon--picto-share {
  @include icon($icon-picto-share) {
    @content;
  }
}

// Save variable
$icon-picto-user: "\EA16";
// Expose as class
.icon.icon-picto-user:before {
  content: $icon-picto-user;
}
// Save mixin
@mixin icon--picto-user {
  @include icon($icon-picto-user) {
    @content;
  }
}

// Save variable
$icon-theme2-arrow: "\EA17";
// Expose as class
.icon.icon-theme2-arrow:before {
  content: $icon-theme2-arrow;
}
// Save mixin
@mixin icon--theme2-arrow {
  @include icon($icon-theme2-arrow) {
    @content;
  }
}

// Save variable
$icon-theme2-play: "\EA18";
// Expose as class
.icon.icon-theme2-play:before {
  content: $icon-theme2-play;
}
// Save mixin
@mixin icon--theme2-play {
  @include icon($icon-theme2-play) {
    @content;
  }
}

// Save variable
$icon-theme2-player-pause: "\EA19";
// Expose as class
.icon.icon-theme2-player-pause:before {
  content: $icon-theme2-player-pause;
}
// Save mixin
@mixin icon--theme2-player-pause {
  @include icon($icon-theme2-player-pause) {
    @content;
  }
}

// Save variable
$icon-theme2-player-play: "\EA1A";
// Expose as class
.icon.icon-theme2-player-play:before {
  content: $icon-theme2-player-play;
}
// Save mixin
@mixin icon--theme2-player-play {
  @include icon($icon-theme2-player-play) {
    @content;
  }
}

// Save variable
$icon-uppercase: "\EA1B";
// Expose as class
.icon.icon-uppercase:before {
  content: $icon-uppercase;
}
// Save mixin
@mixin icon--uppercase {
  @include icon($icon-uppercase) {
    @content;
  }
}

