// Arial Black
@include font-face("Arial-Black", "../fonts/arial-black/Arial-Black", $file-formats: ttf otf eot woff);

// Brandon
@include font-face("BrandonGrotesque", "../fonts/brandon/BrandonGrotesque", $file-formats: ttf svg eot woff);

// Bebas
@include font-face("Bebas", "../fonts/bebas/Bebas", $file-formats: ttf svg eot woff);

// Avenir
@include font-face("AvenirLTStd-Roman", "../fonts/avenir-roman/AvenirLTStd-Roman", $file-formats: otf svg eot woff);
@include font-face("AvenirLTStd-Heavy", "../fonts/avenir-heavy/AvenirLTStd-Heavy", $file-formats: otf svg eot woff);
@include font-face("AvenirLTStd-Light", "../fonts/avenir-light/AvenirLTStd-Light", $file-formats: otf svg eot woff);
@include font-face("AvenirLTStd-Medium", "../fonts/avenir-medium/AvenirLTStd-Medium", $file-formats: otf svg eot woff);

// Comic Sans MS
@include font-face("Comic-Sans-MS", "../fonts/comic-sans-ms/Comic-Sans-MS", $file-formats: otf ttf eot woff);

// Courier New
@include font-face("Courier-New", "../fonts/courier-new/Courier-New", $file-formats: otf ttf eot woff);

// Lora
@include font-face("Lora-Regular", "../fonts/lora-regular/Lora-Regular", $file-formats: ttf svg eot woff);
@include font-face("Lora-Bold", "../fonts/lora-bold/Lora-Bold", $file-formats: ttf svg eot woff);

// OpenSans
@include font-face("OpenSans", "../fonts/opensans-regular/OpenSans-Regular", $file-formats: ttf svg eot woff);
@include font-face("OpenSans-Light", "../fonts/opensans-light/OpenSans-Light", $file-formats: ttf svg eot woff);
@include font-face("OpenSans-ExtraBold", "../fonts/opensans-extrabold/OpenSans-ExtraBold", $file-formats: ttf svg eot woff);

// Oswald
@include font-face("Oswald-Regular", "../fonts/oswald-regular/Oswald-Regular", $file-formats: ttf svg eot woff);
@include font-face("Oswald-Light", "../fonts/oswald-light/Oswald-Light", $file-formats: ttf svg eot woff);
@include font-face("Oswald-Bold", "../fonts/oswald-bold/Oswald-Bold", $file-formats: ttf svg eot woff);

// Roboto
@include font-face("Roboto-Regular", "../fonts/roboto-regular/Roboto-Regular", $file-formats: ttf svg eot woff);
@include font-face("Roboto-Light", "../fonts/roboto-light/Roboto-Light", $file-formats: ttf svg eot woff);
@include font-face("Roboto-Black", "../fonts/roboto-black/Roboto-Black", $file-formats: ttf svg eot woff);

// Snickles
@include font-face("Snickles", "../fonts/snickles/Snickles", $file-formats: ttf svg eot woff);

// Yard Sale
@include font-face("Yard-Sale", "../fonts/yardsale/Yard-Sale", $file-formats: ttf svg eot woff);