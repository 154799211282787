/**
 * All common styles
 */

html, body {
	min-height: 100%;
}

body {
	-webkit-font-smoothing: antialiased;
	font-family: "AvenirLTStd-Roman", Arial, serif;
	color: #363B46;
}

*, *:active, *:focus {
	outline: none !important;
}

a:active, a:focus {
	text-decoration: none;
}

.clearfix {
	clear: both;
}

#container {
	min-height: 100%;
}

.container {
	&::before, &::after {
		display: none;
	}
}

// Align vertically
.v-align {
	display: table;
	width: 100%;
	height: 100%;
	position: relative;

	.m-align {
		display: table-cell;
		position: relative;
		vertical-align: middle;
	}

	.b-align {
		display: table-cell;
		position: relative;
		vertical-align: bottom;
	}
}

/**
 * Preview
 */
#builder.editor #preview.builder {
	position: absolute;
	width: 100%;
	padding-top: 0px;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 0;
	overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

html.preview, html.exported {
	position: relative;
	height: 100%;

	body, #container, .main-container {
		position: relative;
		height: 100%;
	}

	.builder {
		position: relative;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    top: 0;
    width: 100%;
    left: 0;
	}
}

/**
 * Block common style
 */
#builder.editor #preview .block, body.preview .block, #mainContent .block {
	width: 100%;
	height: 100%;
	min-height: 520px;
	position: relative;
}

body.preview .block.block-html, #mainContent .block.block-html {
	min-height: 0px !important;
}

body.preview footer.block, #mainContent footer.block {
	min-height: 0px;
	height: auto;
}

.vjs-icon-placeholder {
	font-size: 0 !important;
	display: none !important;
}

.video-js .vjs-control {
	width: 0;
}